import { axios } from '@/utils/request'
const basic = '/manage/mail'
const api = {
  // 分页查询邮政数据
  getMailList: basic + '/getMailList',
  // 导出excel
  exportExcel: basic + '/exportExcel'
}
export function getMailList (data) {
  return axios({
    url: api.getMailList,
    method: 'post',
    params: data
  })
}
export function exportExcel (data) {
  return axios({
    url: api.exportExcel,
    method: 'post',
    responseType: 'blob',
    params: data,
    timeout: 0
  })
}
