<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="物流单号">
              <a-input v-model="queryParam.mailNo" placeholder="请输入物流单号" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="处理动作">
              <a-select
                v-model="queryParam.action"
                show-search
                :filter-option="filterOption">
                <a-select-option :value="0">收寄</a-select-option>
                <a-select-option :value="10">妥投</a-select-option>
                <a-select-option :value="20">未妥投</a-select-option>
                <a-select-option :value="30">经转过程中</a-select-option>
                <a-select-option :value="40">离开处理中心</a-select-option>
                <a-select-option :value="41">到达处理中心</a-select-option>
                <a-select-option :value="50">安排投递</a-select-option>
                <a-select-option :value="51">正在投递</a-select-option>
                <a-select-option :value="60">揽收</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="处理日期">
              <a-range-picker v-model="date" @change="onChange" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="收件人/快递员电话">
              <a-input v-model="queryParam.phone" placeholder="请输入快递员号码" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="跟进状态">
              <a-select v-model="queryParam.progressStatus">
                <a-select-option value="待跟进">待跟进</a-select-option>
                <a-select-option value="跟进中">跟进中</a-select-option>
                <a-select-option value="已完成">已完成</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="跟进客服">
              <a-select v-model="queryParam.serviceId">
                <a-select-option v-for="(item, key) of serviceList" :value="item.id" :key="key">{{ item.realName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="快递员标记状态">
              <a-select v-model="queryParam.feedbackStatusId" @change="feedBackChange">
                <a-select-option v-for="(item, key) of feedbackStatusList" :value="item.id" :key="key">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="客服营销状态">
              <a-select
                v-model="queryParam.statusId"
                show-search
                :filter-option="filterOption">
                <a-select-option v-for="(item, key) of statusList" :value="item.id" :key="key">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="快递员是否反馈">
              <a-select v-model="queryParam.isFeedback" @change="isFeedChange">
                <a-select-option :value="true">是</a-select-option>
                <a-select-option :value="false">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" :loading="downLoad" icon="vertical-align-bottom" @click="exportClick">
                导出
              </a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      :columns="columns"
      :dataSource="data"
      rowKey="id"
      :loading="loading"
      :pagination="pagination"
      @change="pageChange"
    >
      <span slot="action" slot-scope="text">
        <a-button type="primary" size="small" @click="msgList(text)">
          聊天记录
        </a-button>
        <a-divider type="vertical" />
        <a-button type="primary" size="small" @click="detail(text)">
          快递详情
        </a-button>
      </span>
    </a-table>
    <a-modal
      title="聊天记录"
      :visible="visible"
      :destroyOnClose="true"
      :footer="null"
      @cancel="handleCancel"
      width="850px"
    >
      <div class="message" ref="messageBox" @scroll="scrollEvent">
        <!--            <div v-show="!loading&&messageList>0" class="message-tip">{{ pageData.page < pages?"上拉加载更多":"没有更多了" }}</div>-->
        <a-spin :spinning="loading" size="small" />
        <span class="message-tip" v-if="messageList.length === 0">暂无聊天记录</span>
        <div
          v-for="(item, key) of messageList"
          class="message-info"
          :key="key"
          :class="item.from === mailNo.toString() ? 'to' : 'form'"
        >
          <div>
            {{ item.time }}
          </div>
          <div v-html="item.content"></div>
        </div>
      </div>
    </a-modal>
    <a-modal
      title="快递详情"
      :visible="mailVisible"
      :destroyOnClose="true"
      :footer="null"
      @cancel="mailHandleCancel"
      width="850px"
    >
      <div class="express">
        <div>
          <div>
            <div>物流单号:</div>
            <div>{{ expressBillDetails.id }}</div>
          </div>
          <div>
            <div>快递员:</div>
            <div>{{ expressBillDetails.courierName }}</div>
          </div>
          <div>
            <div>快递员电话:</div>
            <div>{{ expressBillDetails.courierPhone }}</div>
          </div>
        </div>
        <div>
          <div>
            <div>收件人:</div>
            <div>{{ expressBillDetails.recipient }}</div>
          </div>
          <div>
            <div>收件人电话:</div>
            <div>{{ expressBillDetails.phoneNumber }}</div>
          </div>
          <div>
            <div>收件人地址:</div>
            <div>{{ expressBillDetails.address }}</div>
          </div>
        </div>
        <div>
          <div>
            <div>是否紧急:</div>
            <div :class="expressBillDetails.urgent === '紧急' ? 'danger' : ''">{{ expressBillDetails.urgent }}</div>
          </div>
          <div>
            <div>收寄日期:</div>
            <span>{{ expressBillDetails.deliveryDate }}</span>
          </div>
          <div>
            <div>处理日期:</div>
            <div>{{ expressBillDetails.updateTime }}</div>
          </div>
          <div>
            <div>处理动作:</div>
            <div>{{ expressBillDetails.lastAction ? expressBillDetails.lastAction : '' }}</div>
          </div>
          <div>
            <div>处理机构:</div>
            <div>{{ expressBillDetails.orgfullname }}</div>
          </div>
          <div>
            <div>详细信息:</div>
            <div>{{ expressBillDetails.description }}</div>
          </div>
        </div>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { getMailList, exportExcel } from '@/api/delivery/onlineData'
import fileDownload from 'js-file-download'
import { mapState } from 'vuex'
import * as userapi from '@/api/manage/user'
export default {
  name: 'OnlineData',
  data() {
    return {
      pagination: {
        current: 1,
        total: 0,
        showTotal: function(total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50']
      },
      loading: false,
      // 查询参数
      queryParam: {
        page: 0,
        size: 10,
        mailNo: null,
        action: null,
        phone: null,
        startTime: null,
        endTime: null,
        progressStatus: null,
        serviceId: null,
        feedbackStatusId: null,
        isFeedback: null,
        statusId: null
      },
      date: [],
      data: [],
      downLoad: false,
      columns: [
        // {
        //   title: '序号',
        //   dataIndex: 'id'
        // },
        {
          title: '物流单号',
          dataIndex: 'mailNo'
        },
        {
          title: '处理动作',
          dataIndex: 'action'
        },
        {
          title: '处理日期',
          dataIndex: 'updateTime'
        },
        {
          title: '收件人电话',
          dataIndex: 'receiptPhone'
        },
        {
          title: '快递员电话',
          dataIndex: 'expressPhone'
        },
        {
          title: '跟进客服',
          dataIndex: 'customerName'
        },
        {
          title: '跟进状态',
          dataIndex: 'status'
        },
        {
          title: '快递员标记状态',
          dataIndex: 'expressSignStatus'
        },
        {
          title: '快递员反馈信息',
          dataIndex: 'expressFeedback'
        },
        {
          title: '客服营销状态',
          dataIndex: 'statusDesc'
        },
        {
          title: '客服备注',
          dataIndex: 'statusDescRemark'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: process.env.VUE_APP_API_DELIVERY_BASE_URL,
      visible: false,
      mailVisible: false,
      messageList: [],
      mailNo: null,
      pageData: {
        page: 1,
        size: 10
      },
      pages: null,
      expressBillDetails: {},
      feedbackStatusList: [],
      statusList: [],
      serviceList: []
    }
  },
  created() {
    this.query()
    this.getFeedbackStatus()
    this.getStatus()
    this.getService()
  },
  computed: mapState({
    id: state => state.user.info.id
  }),
  methods: {
    feedBackChange() {
      this.queryParam.isFeedback = null
    },
    isFeedChange() {
      this.queryParam.feedbackStatusId = null
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    getFeedbackStatus() {
      const url = `${this.url}/delivery-cloud/mail/findFeedbackStatusList`
      this.axios
        .get(url)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.feedbackStatusList = res.body
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    getStatus() {
      const url = `${this.url}/delivery-cloud/mail/findStatusList`
      this.axios
        .get(url)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.statusList = res.body
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    getService() {
      userapi.findUserList({ page: 0, size: 99999, enabled: 2 }).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.serviceList = res.body.data
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    pageChange(pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick() {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    clearClick() {
      this.queryParam = this.$options.data().queryParam
      this.date = []
    },
    exportClick() {
      const data = { ...this.queryParam }
      delete data.size
      delete data.page
      if (data.startTime === '') {
        data.startTime = null
      }
      if (data.endTime === '') {
        data.endTime = null
      }
      this.downLoad = true
      exportExcel(data)
        .then(res => {
          if (res.status === 500) {
            throw new Error(res.message)
          }
          fileDownload(res, '导出数据.xlsx')
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.downLoad = false
        })
    },
    query() {
      const data = { ...this.queryParam }
      if (data.startTime === '') {
        data.startTime = null
      }
      if (data.endTime === '') {
        data.endTime = null
      }
      this.loading = true
      getMailList(data)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.data = res.body.body
          this.pagination.total = res.body.total
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    msgList(data) {
      this.mailNo = data.mailNo
      this.getHistory(data.mailNo)
      this.visible = true
    },
    scrollEvent(e) {
      if (this.pageData.page < this.pages) {
        if (e.target.scrollTop === 0 && e.target.scrollHeight > 600) {
          console.log(this.pageData.page)
          this.pageData.page += 1
          this.loading = true
          this.axios
            .get(`${this.url}/delivery-cloud/history`, {
              params: { number: this.mailNo, page: this.pageData.page, size: this.pageData.size }
            })
            .then(res => {
              if (res.status !== 200) {
                throw new Error(res.message)
              }
              const msg = res.body.list.sort((t1, t2) => {
                return t2.time < t1.time ? 1 : -1
              })
              const old = e.target.scrollHeight
              this.messageList.unshift(...msg)
              this.$nextTick(() => {
                const news = e.target.scrollHeight
                const ele = this.$refs.messageBox
                ele.scrollTop = news - old
              })
            })
            .catch(e => {})
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    getHistory(id) {
      this.axios.get(`${this.url}/delivery-cloud/history`, { params: { number: id, page: 1, size: 10 } }).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.messageList = res.body.list.sort((t1, t2) => {
          return t2.time < t1.time ? 1 : -1
        })
        console.log(this.messageList)
        this.scrollTop()
        this.pages = Math.ceil(res.body.total / 10)
        this.pageData.page = 1
      })
    },
    scrollTop() {
      this.$nextTick(() => {
        const ele = this.$refs.messageBox
        ele.scrollTop = ele.scrollHeight
      })
    },
    handleCancel() {
      this.visible = false
    },
    detail(data) {
      this.mailDetail(data.mailNo)
      this.mailVisible = true
    },
    mailDetail(id) {
      const url = `${this.url}/delivery-cloud/mail/detail`
      this.axios
        .get(url, { params: { number: id } })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.expressBillDetails = res.body
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    mailHandleCancel() {
      this.mailVisible = false
    }
  }
}
</script>

<style scoped>
.message {
  height: 600px;
  border-bottom: 1px solid #f0f0f0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
}
.message > div {
  margin: 10px 0;
}
.message > div > div:nth-child(2) {
  padding: 12px 10px;
  border-radius: 4px;
  margin-top: 10px;
  max-width: 276px;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  width: auto;
  display: inline-block !important;
  /*white-space: normal;*/
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-line;
}
.message-info a {
  pointer-events: none;
}
.message-tip {
  display: inline-block;
  border-radius: 5px;
  margin: 5px auto !important;
  background: #dfdfdf;
  padding: 5px;
}
.to {
  align-self: flex-start;
}
.form > div:nth-child(2) {
  background: #dadada;
  text-align: left;
}
.form {
  align-self: flex-end;
  text-align: right;
}
.to > div:nth-child(2) {
  background: #ddf2fc;
}
.express::-webkit-scrollbar,
.message::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.express::-webkit-scrollbar-track,
.message::-webkit-scrollbar-track {
  border-radius: 2px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.express::-webkit-scrollbar-thumb,
.message::-webkit-scrollbar-thumb {
  border-radius: 2px; /*滚动条的圆角*/
  background-color: skyblue;
}
.express {
  height: 548px;
  overflow-y: auto;
}
.express > div {
  border-top: 1px solid #f0f0f0;
  padding: 20px 20px;
  font-size: 16px;
}
.express > div > div {
  display: flex;
}
.express > div > div > div:first-child {
  width: 100px;
  color: #666666;
}
.express > div > div > div:last-child {
  width: 100px;
  color: #333333;
  font-weight: 500;
  flex: auto;
}
.express > div > div:nth-child(n + 2) {
  margin-top: 15px;
}
.danger {
  color: #ff0200 !important;
}
</style>
